<template>
  <div id="app" class="app">
    <router-view />
  </div>
</template>

<script>
import { localize } from 'vee-validate'

import './assets/styles/index.scss'
import './assets/styles/__owerrides.scss'

export default {
  name: 'App',

  beforeCreate() {
    localize('ru')
  },
}
</script>

<style lang="scss">
.app {
  width: 100%;
  max-width: 550px;
}
</style>
