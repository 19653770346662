import Vue from 'vue'
import App from './App.vue'

import Buefy from 'buefy'

import { ValidationObserver, ValidationProvider } from 'vee-validate'

import api from '@/api'
import router from '@/router'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.use(Buefy)

Vue.config.productionTip = false

Vue.prototype.$api = api

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
