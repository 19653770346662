import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			name: 'main',
			component: () => import('@/views/Main'),
		},
        {
            path: '/policy',
            name: 'policy',
            component:() => import('@/views/Policy')
        }
	]
})

export default router
